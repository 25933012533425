import React from 'react';
import Head from '../organisms/head/index';
import Body from '../organisms/body/index';

export default function singelStory(props) {

    const bodyData = props?.pageContext?.data;
    const data ={
        title: bodyData?.edge?.seoContent?.metaTile,
        description: bodyData?.edge?.seoContent?.metaDescription,
        image:bodyData?.edge?.seoContent?.metaImage?.sourceUrl,
        keywords:bodyData?.edge?.seoContent?.metaKeywords,
    }
    return (
        <>
            <Head data={data} url={bodyData?.edge?.uri}/>
            <Body bodyData = {bodyData}  />
        </>
    )
}
